import queryString from 'query-string';
import { Product } from '../types/Product';

export function mapQueryStringValues(parsedQuery: queryString.ParsedQuery<string>, product: Product) {
    const mappedOptions = [];

    for (const key of Object.keys(parsedQuery)) {
        switch (key) {
            case 'dh':
                mappedOptions.push({
                    name: 'Drive Horsepower',
                    ...buildOptionMap(parsedQuery['dh'] as string, 'Drive Horsepower', product)
                });
                break;
            case 'lr':
                mappedOptions.push({
                    name: 'Line Reactor',
                    ...buildOptionMap(parsedQuery['lr'] as string, 'Line Reactor', product)
                });
                break;
            case 'of':
                mappedOptions.push({
                    name: 'Output Filter',
                    ...buildOptionMap(parsedQuery['of'] as string, 'Output Filter', product)
                });
                break;

            case 'dmh':
                mappedOptions.push({
                    name: 'Door Mounted HIM',
                    ...buildOptionMap(parsedQuery['dmh'] as string, 'Door Mounted HIM', product)
                });
                break;
            case 'vi':
                mappedOptions.push({
                    name: 'Voltage Indicator',
                    ...buildOptionMap(parsedQuery['vi'] as string, 'Voltage Indicator', product)
                });
                break;
            default:
                break;
        }
    }

    return mappedOptions;
}

function buildOptionMap(value: string, key: string, product: Product) {
    const matchingOption = product.options.find((o) => o.name === key);

    if (!matchingOption) {
        return {};
    }

    if (matchingOption.type === 'selection') {
        const matchingValue = matchingOption.values.find((o) => o.text === value);

        return {
            plusOrMinus: matchingValue.plusOrMinus,
            price: matchingValue.priceAdjust,
            value
        };
    }

    const matchingValue = value;

    return {
        plusOrMinus: matchingValue === 'Yes' ? '+' : '-',
        price: matchingOption.priceAdjust,
        value
    };
}
