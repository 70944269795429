import React, { useEffect, useState } from 'react';
import { Product } from '../../foundation/types/Product';
import Sticky from 'react-sticky-el';
import SEO from '../presentation/components/seo';
import { adjustPrice } from '../../foundation/helpers/adjustPrice';
import StickyImage from '../presentation/components/stickyImage';
import ConfigurationHeader from '../presentation/components/configurationHeader';
import ConfigurationOptions from '../presentation/components/configurationOptions';
import { useLocation } from '@reach/router';
import queryString from 'query-string';
import { buildProductId } from '../../foundation/helpers/buildProductId';
import { mapQueryStringValues } from '../../foundation/helpers/mapQueryStringValues';
import { optionsToQueryString } from '../../foundation/helpers/optionsToQueryString';

interface Props {
    allDrivesYaml: any;
    site: any;
}

const ProductTemplate: React.FC<Props> = ({ allDrivesYaml, site }) => {
    const location = useLocation();
    const { edges } = allDrivesYaml;
    const { siteUrl } = site.siteMetadata;
    const productNode = edges[0].node as Product;

    let defaultValues = [
        {
            name: 'Drive Horsepower',
            plusOrMinus: '+',
            price: 0,
            value: '20HP'
        },
        {
            name: 'Line Reactor',
            plusOrMinus: '+',
            price: 500.0,
            value: 'Yes'
        },
        {
            name: 'Output Filter',
            plusOrMinus: '-',
            price: 5500.0,
            value: 'No'
        },
        {
            name: 'Door Mounted HIM',
            plusOrMinus: '-',
            price: 1500.0,
            value: 'No'
        },
        {
            name: 'Voltage Indicator',
            plusOrMinus: '+',
            price: 0,
            value: 'None'
        }
    ];

    if (location.search) {
        const queryParsed = queryString.parse(location.search);
        defaultValues = mapQueryStringValues(queryParsed, productNode);
    }

    const [price, setPrice] = useState(productNode.basePrice || 0);
    const [description, setDescription] = useState('');
    const [queryStringValue, setQueryStringValue] = useState(location.search);
    const [selectedOptions, setSelectedOptions] = useState<{ name: string; plusOrMinus: string; price: number; value: string }[]>(defaultValues);

    useEffect(() => {
        setPrice(adjustPrice(productNode.basePrice, selectedOptions));
        setDescription(
            selectedOptions
                .map((s) => {
                    return ` ${s.name}: ${s.value}`;
                })
                .join(',')
        );
        setQueryStringValue(optionsToQueryString(selectedOptions));
    }, []);

    useEffect(() => {
        setPrice(adjustPrice(productNode.basePrice, selectedOptions));
        setDescription(
            selectedOptions
                .map((s) => {
                    return ` ${s.name}: ${s.value}`;
                })
                .join(',')
        );
        setQueryStringValue(optionsToQueryString(selectedOptions));
    }, [selectedOptions]);

    return (
        <>
            <SEO title={'Drive in a Box'} description={'testing'} />

            <Sticky stickyStyle={{ zIndex: 10 }} hideOnBoundaryHit={false}>
                <header className="o-container navbar navbar-expand navbar-dark flex-column flex-md-row bd-navbar" style={{ backgroundColor: 'white' }}>
                    <img src="/images/logo.jpg" height="60" />
                </header>
            </Sticky>
            <div className="c-configurator u-margin-bottom--lg">
                <div className="o-container">
                    <div className="o-grid configurator__sticky-wrapper-mobile">
                        <div className="o-col-12 o-col-7--lg u-push-1--lg">
                            <StickyImage product={productNode} price={price} />
                        </div>
                        <div className="o-col-12 o-col-4--lg u-flex-first--lg">
                            <ConfigurationHeader
                                selectionOptions={selectedOptions}
                                siteUrl={siteUrl}
                                description={description}
                                product={productNode}
                                price={price}
                                queryString={queryStringValue}
                            />
                            <ConfigurationOptions product={productNode} selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} />
                            <button
                                className="snipcart-add-item mobile-button btn btn-xl btn-success"
                                data-item-id={buildProductId(selectedOptions)}
                                data-item-price={price}
                                data-item-image={productNode.image}
                                data-item-name={productNode.name}
                                data-item-description={description}
                                data-item-url={`${siteUrl}/.netlify/functions/order-validation${queryStringValue}`}
                            >
                                Add To Cart
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default ProductTemplate;
