export function buildProductId(options: { name: string; plusOrMinus: string; price: number; value: string }[]) {
    let baseProductId = 'drv-';

    // drive horse power should be first
    const dh = options.find((o) => o.name === 'Drive Horsepower');
    const vi = options.find((o) => o.name === 'Voltage Indicator');
    baseProductId += dh ? dh.value : '';

    for (const option of options.sort((a, b) => (a.name < b.name ? -1 : 1))) {
        baseProductId += option.value === 'Yes' ? option.name[0] : '';
    }

    // voltage indicator should be last
    baseProductId += vi ? vi.value : '';

    return baseProductId;
}
