export function formatMoney(amount: number) {
    const options = {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
    };

    const formatter = new Intl.NumberFormat('en-US', options);

    if (!amount) {
        return undefined;
    }

    if (amount === 0) {
        return '$0';
    }

    if (amount < 0) {
        return `(${formatter.format(Math.abs(amount))})`;
    }

    return formatter.format(amount);
}
