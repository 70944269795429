import { OptionValue } from '../types/DriveOption';

export function adjustPrice(price: number, options: { name: string; plusOrMinus: string; price: number }[]) {
    if (!options) {
        return price;
    }
    var basePrice = price;
    for (const option of options) {
        if (option.plusOrMinus === '+') {
            basePrice += option.price;
        } else {
            basePrice -= option.price;
        }
    }

    return basePrice;
}
