import React from 'react';
import Sticky from 'react-sticky-el';
import { formatMoney } from '../../../foundation/helpers/formatMoney';
import { Product } from '../../../foundation/types/Product';

interface Props {
    price: number;
    product: Product;
}

const StickyImage: React.FC<Props> = ({ price, product }) => {
    return (
        <div className="c-sticky configurator__sticky" id="configurator" style={{ zIndex: 10 }}>
            <Sticky stickyStyle={{ zIndex: 10, marginTop: '70px' }} topOffset={-70}>
                <div className="configurator__configuration-wrapper sticky__scroll-element">
                    <div className="configurator__configuration">
                        <div className="configurator__images">
                            <div className="carousel__slide is-selected" aria-hidden="false">
                                <figure className="c-image figure image--object-fit-contain image--is-loaded">
                                    <div className="configurator__configuration-sidebar">
                                        <h1 className="configurator__title u-hidden-up--lg">{product.name}</h1>
                                        <div className="configurator__configuration-title">{`Your ${product.name}`}</div>
                                        <div className="configurator__configuration-price">
                                            <div className="c-price">
                                                <span className="u-sr-only">Price: {formatMoney(price)}</span>
                                                <span className="price__items" aria-hidden="true">
                                                    <span className="price__item price__item--sales">
                                                        <span className="price__value">
                                                            <span className="u-sr-only">Price: </span>
                                                            {formatMoney(price)}
                                                        </span>
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <img className="image__default u-hidden-with-nojs" src={product.image} />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </Sticky>
        </div>
    );
};

export default StickyImage;
