import ProductTemplate from '../templates/productTemplate';
import { graphql, useStaticQuery } from 'gatsby';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../static/css/_product.scss';
import React from 'react';

const Index = () => {
    const data = useStaticQuery(graphql`
        query {
            allDrivesYaml(limit: 1) {
                edges {
                    node {
                        id
                        name
                        basePrice
                        image
                        description
                        options {
                            name
                            plusOrMinus
                            priceAdjust
                            type
                            values {
                                text
                                plusOrMinus
                                priceAdjust
                            }
                        }
                    }
                }
            }

            site {
                siteMetadata {
                    siteUrl: url
                }
            }
        }
    `);

    return <ProductTemplate {...data} />;
};

export default Index;
