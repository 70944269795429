import React from 'react';
import { formatMoney } from '../../../foundation/helpers/formatMoney';
import { DriveOption, OptionValue } from '../../../foundation/types/DriveOption';
import { Product } from '../../../foundation/types/Product';
import DriveOptionButtonGroup from './driveOptionButtonGroup';

interface Props {
    product: Product;
    selectedOptions: { name: string; plusOrMinus: string; price: number; value: string }[];
    setSelectedOptions: (options: { name: string; plusOrMinus: string; price: number; value: string }[]) => void;
}

const ConfigurationOptions: React.FC<Props> = ({ product, selectedOptions, setSelectedOptions }) => {
    const dropdownSelectionChanged = (selectedOption: OptionValue, option: DriveOption) => {
        const filteredOptions = selectedOptions ? selectedOptions.filter((s) => s.name !== option.name) : [];

        filteredOptions.push({ name: option.name, plusOrMinus: selectedOption.plusOrMinus, price: selectedOption.priceAdjust, value: selectedOption.text });
        setSelectedOptions(filteredOptions);
    };

    return (
        <div className="configurator__products">
            <form>
                {product.options.map((option) => {
                    const matchingSelectedOption = selectedOptions && selectedOptions.find((s) => s.name === option.name);
                    const additionalCost = matchingSelectedOption ? (matchingSelectedOption.plusOrMinus === '-' ? 0 : matchingSelectedOption.price) : 0;

                    let existingSelectionIndex = undefined;
                    if (option.values) {
                        if (matchingSelectedOption) {
                            existingSelectionIndex = option.values.findIndex((o) => o.text === matchingSelectedOption.value || '');
                        }
                    } else {
                        if (matchingSelectedOption) {
                            if (matchingSelectedOption.value === 'Yes') {
                                existingSelectionIndex = 0;
                            } else if (matchingSelectedOption.value === 'No') {
                                existingSelectionIndex = 1;
                            }
                        }
                    }

                    return (
                        <div key={option.name} className="c-configurator-product u-padding-top--md u-padding-bottom--md">
                            <div className="configurator-product__info">
                                <div>
                                    <h3 className="configurator-product__title">{option.name}</h3>
                                    <div className="configurator-product__variations">
                                        {
                                            <DriveOptionButtonGroup
                                                existingSelectionIndex={existingSelectionIndex}
                                                driveOption={option}
                                                values={option.values}
                                                onChange={(selectedOption: OptionValue | DriveOption) => {
                                                    if ((selectedOption as OptionValue).text) {
                                                        dropdownSelectionChanged(selectedOption as OptionValue, option);
                                                    } else {
                                                        const driveOption = selectedOption as DriveOption;
                                                        const filteredOptions = selectedOptions ? selectedOptions.filter((s) => s.name !== driveOption.name) : [];

                                                        filteredOptions.push({
                                                            name: option.name,
                                                            plusOrMinus: driveOption.plusOrMinus,
                                                            price: driveOption.priceAdjust,
                                                            value: driveOption.plusOrMinus === '+' ? 'Yes' : 'No'
                                                        });
                                                        setSelectedOptions(filteredOptions);
                                                    }
                                                }}
                                            />
                                        }
                                    </div>
                                    <div className="configurator-product__selected-info">
                                        <span className="configurator-product__extra-price">Additional Cost: {selectedOptions ? formatMoney(additionalCost) || '$0' : ''}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </form>
        </div>
    );
};

export default ConfigurationOptions;
