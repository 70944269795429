export function optionsToQueryString(
    options: {
        name: string;
        plusOrMinus: string;
        price: number;
        value: string;
    }[]
) {
    let queryAbbreviations = [];

    for (const option of options) {
        switch (option.name) {
            case 'Drive Horsepower':
                queryAbbreviations.push(`dh=${option.value}`);
                break;
            case 'Line Reactor':
                queryAbbreviations.push(`lr=${option.value}`);
                break;
            case 'Output Filter':
                queryAbbreviations.push(`of=${option.value}`);
                break;
            case 'Door Mounted HIM':
                queryAbbreviations.push(`dmh=${option.value}`);
                break;
            case 'Voltage Indicator':
                queryAbbreviations.push(`vi=${option.value}`);
                break;
        }
    }

    return encodeURI(`?${queryAbbreviations.join('&')}`);
}
