import React, { useState } from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import { DriveOption, OptionValue } from '../../../foundation/types/DriveOption';

interface Props {
    values?: OptionValue[];
    driveOption: DriveOption;
    existingSelectionIndex?: number;
    onChange: (selectedOption: OptionValue | DriveOption) => void;
}

const DriveOptionButtonGroup: React.FC<Props> = ({ driveOption, existingSelectionIndex, values, onChange }) => {
    const [selectedOption, setSelectedOption] = useState<OptionValue>();
    const [selectedSelectionIndex, setSelectedSelectionIndex] = useState<number>(existingSelectionIndex);
    const [selectedOtherIndex, setSelectedOtherIndex] = useState<number>(existingSelectionIndex);

    if (driveOption.type === 'selection') {
        return (
            <ButtonGroup size="lg">
                {values.map((value, index) => (
                    <Button
                        active={selectedSelectionIndex !== undefined ? selectedSelectionIndex === index : false}
                        color="secondary"
                        outline
                        key={value.text}
                        value={value.text}
                        onClick={(e) => {
                            setSelectedSelectionIndex(index);
                            setSelectedOption(value);
                            onChange(value);
                        }}
                    >
                        {value.text}
                    </Button>
                ))}
            </ButtonGroup>
        );
    }

    return (
        <ButtonGroup size="lg">
            <Button
                color="secondary"
                outline
                active={selectedOtherIndex !== undefined ? selectedOtherIndex === 0 : false}
                value="Yes"
                onClick={(e) => {
                    setSelectedOtherIndex(0);
                    onChange(driveOption);
                }}
            >
                Yes
            </Button>
            <Button
                color="secondary"
                outline
                active={selectedOtherIndex !== undefined ? selectedOtherIndex === 1 : false}
                value="No"
                onClick={(e) => {
                    setSelectedOtherIndex(1);
                    onChange({ ...driveOption, plusOrMinus: '-' });
                }}
            >
                No
            </Button>
        </ButtonGroup>
    );
};

export default DriveOptionButtonGroup;
