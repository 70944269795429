import React from 'react';
import { buildProductId } from '../../../foundation/helpers/buildProductId';
import { Product } from '../../../foundation/types/Product';

interface Props {
    description: string;
    queryString: string;
    price: number;
    product: Product;
    siteUrl: string;
    selectionOptions: { name: string; plusOrMinus: string; price: number; value: string }[];
}

const ConfigurationHeader: React.FC<Props> = ({ description, price, product, queryString, siteUrl, selectionOptions }) => {
    return (
        <div className="configurator__info">
            <h1 className="configurator__title u-hidden u-visible-up--lg" style={{ color: 'black' }}>
                {product.name}
            </h1>
            <div className="configurator__instructions u-padding-top--sm u-padding-bottom--sm">
                <h2 className="o-subheading">Customize your box</h2>
                <p className="o-subtitle">Use the options below to configure your box</p>

                <button
                    className="snipcart-add-item screen-only btn btn-xl btn-success"
                    data-item-id={buildProductId(selectionOptions)}
                    data-item-price={price}
                    data-item-image={product.image}
                    data-item-name={product.name}
                    data-item-description={description}
                    data-item-url={`${siteUrl}/.netlify/functions/order-validation${queryString}`}
                >
                    Add To Cart
                </button>
            </div>
        </div>
    );
};

export default ConfigurationHeader;
